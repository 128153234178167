<template>
    <!-- <div v-html="$t('RefundsLang.html')"></div> -->
    <div class="right-cell">
    <div class="cell-view">
        <div class="cell-title">Platform Return Regulations</div>
        <div class="cell-spant">• Any goods not sold by ROSEONLY.;</div>
        <div class="cell-spant">• Any goods that are not maintained in good condition;</div>
        <div class="cell-spant">• It will be 24 hours after the actual receipt for flower products, and seven days after the actual receipt for other products; </div>
        <div class="cell-spant">• Any goods with quality problems caused by abnormal use, storage or improper maintenance;</div>
        <div class="cell-spant">• Flower series products and other customized products without any quality problems;</div>
        <div class="cell-spant">• Due to incorrect address or phone number of the consignee or refusal by the consignee, etc., the goods are refused to be signed or the express delivery cannot be delivered normally;</div>
        <div class="cell-spant">• Any in-store pick-up orders will no longer support returns or exchanges after pick-up;</div>
        <div class="cell-spant">• Other goods that should not be returned or exchanged according to law and agreement.</div>
    </div>
    <div class="cell-view">
        <div class="cell-title">Return and exchange service</div>
        <div class="cell-spant">• Flowers are highly perishable commodities. If you find serious damage (such as wilting or fading flowers) when signing for the goods, please take photos and initiate the return or exchange process within 24 hours after signing for the goods.</div>
        <div class="cell-spant">• If you require a return or exchange, flowers are highly perishable commodities. If you find serious damage (such as wilting or fading flowers) when signing for the goods, please take photos and initiate the return or exchange process within 24 hours after signing for the goods.</div>
        <div class="cell-spant">• If you require a return or exchange, please click "Personal Center" and select "Return Instructions" in the left column; you can also choose to initiate the return or exchange process by sending the receipt and order number to service@roseonly.com.</div>
        <div class="cell-spant">• Please be sure to return the included accessories, gifts (if any), warranty cards, instructions, invoices, test reports (for products that require inspection reports for returns and exchanges) with the product; if the gifts have been used, they are not eligible for return or exchange. The main product has been returned, and the gift amount will be deducted. If the goods that need to be replaced are returned and confirmed to be complete, they will be re-shipped within 3 working days.</div>
        <div class="cell-spant">• The shipping costs incurred for returns and exchanges due to quality problems of the product itself will be borne by ROSEONLY.</div>
        <div class="cell-spant">• The gemstones used in jewelry products are all natural gemstones and contain inclusions, growth patterns, color bands, bubbles, mineral impurities, etc., which are the physical characteristics of natural gemstones and do not constitute quality issues.</div>
        <div class="cell-spant">• The products and information are for reference only. Due to problems such as shooting lighting and color differences on different monitors, there may be a certain color difference between the product pictures and the actual product. Please refer to the actual product.After-sales service instructions</div>
    </div>
    <div class="cell-view">
        <div class="cell-title">After-sales service instructions</div>
        <div class="cell-spant">• ROSEONLY. Jewelry has a 6-month warranty period and enjoys free repair and maintenance services during the warranty period; we will also provide you with lifelong professional refurbishment services (only once a year);</div>
        <div class="cell-spant">• With shopping receipts and invoices, you can change the ring for free (if the ring cannot be changed, it is recommended to have it re-customized);</div>
        <div class="cell-spant">• After the warranty period, if problems such as shedding, breakage, fading, or oxidation occur, professional paid maintenance services can be provided. Please consult customer service for specific details.</div>
    </div>
</div>
</template>
  
  
  
  
<script name="Refund" setup>
import { onMounted } from 'vue'

onMounted(() => {
    scrollTop()
})
const scrollTop = () => {
    window.scrollTo(0, 0); // 将页面滚动到顶部
}

</script>
  
<style lang="scss">
.right-cell {
    font-size: 14px;

    .cell-title {
        // height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-weight: 700;
    }

    .cell-view {
        margin: 20px 0;
        padding-bottom: 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #d9d9d9;
    }

    .cell-spant {
        line-height: 30px;
    }
}
</style>